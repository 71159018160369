:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 0.5em 0 0;
    
    
    /* change colors for topmenu */
    --topmenu-bg: white;
    --topmenu-color: black;
    --topmenu-li-a-padding: 10px 25px;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/

section {
    min-height:50vh;
    padding:5% 0;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.green {
    background-color:#40B65E;
}

#menu-icon {
    z-index:100;
}

#menu-icon span {
    border:2px solid black;
}

.topmenu {
    margin-top:1em;
    border-top:#eee solid 1px;
}

.page {
    min-height:80vh;
}

.topbanner {
 padding-top: 1em;
    background: #40B65E;
    color: white;
    
}

.topbanner h3 {
    color: white;
    text-align: center
    
}
/* header
---------------------------------------------------------*/
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
    text-transform:uppercase;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:#3DB764;
}

.menu .first a {
    padding-left:0;
}

.menu .dropdown ul {
    background:#fff;
    z-index:100;
}

.menu .dropdown li a {
    text-transform:none;
    padding:10px 25px;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

.topcontact ul {
    margin:0;
}

.topcontact li {
    display:inline-block;
    margin-right:0.5em;
}

.subMenu {
    background:#40B65E;
    padding:0.5em 0; 
}

.subMenu ul {
    list-style:none;
    margin: 0 ;
    padding:0;
}

.subMenu ul li {
    display:inline-block;
}

.subMenu ul li a {
    display:block;
    padding:8px 10px;
    color:white;
    text-decoration:none;
}

.subMenu ul .active a, .subMenu ul li a:hover {
    text-decoration:underline;   
}

/* slider
---------------------------------------------------------*/


.slick-slider {
    margin:0 auto;
    position: relative;
}
.startsida .slick-track {
    height: 45vh;
}

.slick-slide {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.bildspel_titel  {
    width:100%;
    background:rgba(255,2555,255,0.3);
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align:center;
    color:#fff;
    display:block;
    margin: 0 auto;
}

.bildspel_titel h1, .bildspel_titel h2 {
    font-size: 3.8rem;
    margin:0;
    font-weight:700;
    color:white;
    text-align:center;
}

.bildspel_titel .container {
    max-width:880px;
    font-size: 1.3rem;
}
 
.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    font-size:5rem;
}

.slick-dots {
    bottom:10px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color:#3DB764;
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:#3DB764;
    display:block;
}

.slick-dots li {
    border:#3DB764 solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

    

/* Startbox / start-block-wrappe
---------------------------------------------------------*/
.start-block-wrappe {
    min-height:5vh;
    padding:1.5em 0;
    color:white;
}

.start-block-wrappe h1 {
    color:white;
    font-size:2.6rem;
    text-transform:uppercase;
    letter-spacing:-1px;
}


.startbox {
    text-decoration:none;
    color:#111;
}

.startbox article {
    margin-bottom:1.5em;
}

.pop-img img {
    width:100%;
}

.startbox-inner {
    padding:0 1.5em;
    display:block;
    position:relative;
}

.block-content {
    color:#111;
}

.block-content p {
    margin-top:0;
}

.block-rub h2 {
    color:#251408;
    font-size:2.0rem;
    font-weight:bold;
    margin-bottom:0;
}

.boxLink {
    padding-bottom:1.5em;
}

/* block2
---------------------------------------------------------*/
section.start-block-wrappe2 {
    padding:1.5em 0;
    min-height:5vh;
}

.block2-content {
    text-align:center;
    max-width:850px;
    margin:0 auto;
    width:100%;
}

/* block3
---------------------------------------------------------*/
.start-block-wrapper3 .container > h2 {
    text-align:center;
}

/* block4
---------------------------------------------------------*/
.start-block-wrapper4 {
    color:white;
}

.start-block-wrapper4 h3 {
    color:white;
    font-weight:bold;
}

.start-block-wrapper4 button {
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

/* block5
---------------------------------------------------------*/
section.start-block-wrapper5 {
    padding:0;
}

/* Footer
---------------------------------------------------------*/


footer {
    color: white;
}

footer h4 {
    margin: 0;
    color: #C1FFD1;
    font-size: 1.5rem;
    border-bottom: #32944B solid 1px;
}

footer .contact-prod ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer .contact-prod li {
    display: block;
    border-bottom: #32944B solid 1px;
}

footer .contact-prod a {
    display: block;
    padding: 0.3em 0;
    text-decoration: none;
    color: #C1FFD1;
}

footer .contact-prod a:hover {
    color: white;
}

footer .contact-box {
    font-size: 0.9rem;
    color: #C1FFD1;
}

footer .contact-box span {
    display: block;
}

footer .contact-box a {
    color: #C1FFD1;
    text-decoration: none;
}

footer .contact-box a:hover {
    color: white;
}

footer .contact-box .contact-adress {
    padding-bottom: 0.5em;
    border-bottom: #32944B solid 1px;
}

footer .contact-box .phone {
    border-bottom: #32944B solid 1px;
    padding: 0.5em 0;
}

footer .contact-box .mail {
    padding: 0.5em 0;
}

.topsocial ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.topsocial li {
    display: inline-block;
    margin-right: 0.5em;
}

.topsocial li a {
    text-decoration: none;
    color:#fff;
    font-size:1.3rem
}

.topsocial li a:hover {
    color:#5D2976;
}

.bottom-logo img {
    max-width:60%;
}

/* news
---------------------------------------------------------*/

.date {
    display:block;
    font-style:italic;
}

.back-btn {
    margin-top:0.6em;
}

.readmore {
    margin-top:0.6em;
}

/* page
---------------------------------------------------------*/

* {
    min-width:0;
}

hr {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
    border: none;
    height: 1px;
    background-color: #40B65E;
    clear: both;
}

.adress span, .c-info span {
    display:block;
}

.article-img {
    overflow:hidden;
}

.articleSliderSmall {
    width:auto !important;
}

.articleSliderSmall img {
    margin-right:1px;
    border:#C3C3C3 solid 1px;
}

.fastigheter  div {
    margin-top:1em;
}

.fastig-map {
    margin-top: 1.5em;
    border:#eee solid 1px;
    background:#eee;
}

.fastig-map-inner {
    padding:1.5em;
}

.fastig-map-inner .map-canvas {
    height: 30vh;
}

.adress span {
    display:block;
}

.left-side {
    margin-left:0;
}

/* other
---------------------------------------------------------*/

.findus {
    display:block;
    margin-top:0.6em;
}

.fa-map-marker:before {
    font-size:3.0rem;
}

.boltform textarea {
    min-height:120px;
}

.boltform label {
    display: none;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

.button, button, input[type=reset], input[type=submit] {
    margin:0;
    background:#40B65E;
    border:#40B65E solid 1px;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.button:hover, button:hover, input[type=reset]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

button[type=submit], input[type=submit] {
    background:#40B65E;
}

button[type=submit]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

a {
    color:#40B65E;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color:#000;
}

body {
    font-family: OpenSans;
    font-size:0.9rem;
    color:#000;
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    color:#251408;
    margin-top:0;
}

h1 {
    font-weight:700;
}

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {}

@media (max-width: 550px) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:70vw;
    }
    .homepage header {
        background:transparent;
    }
    .topcontact {
        display:none;
    }
    .slick-track {
        height:35vh;
    }
}

@media (max-width: 750px) and (min-width: 551px ) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:40vw;
    }
    

}

@media (max-width: 800px) {
    .bildspel_titel .container {
        font-size:1.1em;
    }
    .logo img {
        max-width:70%;
    }
    #menu-icon {
        position:absolute;
        top:12px;
        right:10px;
    }
    .zoombox h1, .zoombox h2 {
        font-size:1.7rem;
    } 
    .slider-nav {
        display:none !important;
    }
    .slick-track {
        height:auto !important;
    }
}

@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .topmenu nav, .menu .dropdown ul {
        background:#40b65e;
    }

    .menu li a {
        color: white;
    }

    
    .menu .active a {
        background:transparent;
        color:white;
    }
    .startsida .slick-track {
        height:60vw;
    }

}

@media (min-width: var(--breakpoint-menu)) {
    .slider-caption-outer {
    bottom: 0;
    left: 0;
    overflow: visible;
    position: absolute;
    text-align: center;
    right: 0;
    top: 0;
    }
    .slider-caption {
    position: absolute;
    bottom: 30%;
    width: 100%;
    right: 0;
    text-align: right;
    display: block;
    padding: 0;
    z-index: 3;
    }
}
